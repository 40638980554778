<template>
    <div>
        <div>
            <v-col cols="12" sm="4">
                <h4>Busqueda de historial de Paciente</h4>
            </v-col>
        </div>

        <template>
            <v-container fluid>
                <v-row>
                    <v-col>
                        <v-text-field disabled v-model="currentPaciente.documento" counter="8" type="number"
                            label="Documento"></v-text-field>
                    </v-col>
                    <v-col>
                        <v-text-field disabled class="styled-input" v-model="currentPaciente.nombre" type="text"
                            label="Nombre"></v-text-field>
                    </v-col>
                    <v-col>
                        <v-text-field disabled class="styled-input" v-model="currentPaciente.apellido" type="text"
                            label="Apellido"></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-spacer></v-spacer>
                    <v-col cols="12" sm="2" md="4">
                        <v-menu v-model="fechainicio_datePicker" :close-on-content-click="false" :nudge-right="40"
                            transition="scale-transition" offset-y min-width="290px">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field v-model="dateStart" label="Rango inicial" readonly v-bind="attrs"
                                    v-on="on"></v-text-field>
                            </template>
                            <v-date-picker v-model="dateStart" @input="fechainicio_datePicker = false"></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="12" sm="2" md="4">
                        <v-menu v-model="fechafinal_datePicker" :close-on-content-click="false" :nudge-right="40"
                            transition="scale-transition" offset-y min-width="290px">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field v-model="dateEnd" label="Rango final" readonly v-bind="attrs"
                                    v-on="on"></v-text-field>
                            </template>
                            <v-date-picker v-model="dateEnd" @input="fechafinal_datePicker = false"></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="12" sm="2" md="4">
                        <v-btn size="small" color="primary">
                            Filtrar
                        </v-btn>
                    </v-col>
                    <v-spacer></v-spacer>
                </v-row>
                <v-spacer></v-spacer>
            </v-container>
        </template>

        <template>
            <div v-if="consultas == ''">
                <v-expansion-panels v-model="panel" multiple>
                    <v-expansion-panel>
                        <v-expansion-panel-header disabled><strong> <v-icon color="info" small>mdi-information</v-icon> Este
                                paciente no tiene historia
                                registrada</strong></v-expansion-panel-header>
                        <v-expansion-panel-content>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </div>
            <div v-else>
                <v-expansion-panels v-model="panel" multiple>
                    <v-expansion-panel>
                        <v-expansion-panel-header>Historia de Paciente</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-timeline>
                                <v-timeline-item v-for="(consulta, i) in consultas" :key="i" small>

                                    <template v-slot:opposite>
                                        <div>
                                            <span :class="`headline font-weight-bold ${consulta.id}--text`"
                                                v-text="`${consulta.fechaConsulta}`">
                                            </span>
                                        </div>
                                        <div>
                                            <span class="red--text" v-if="consulta.seguimientoConsulta"><strong> Requiere
                                                    Seguimiento!</strong>
                                            </span>
                                        </div>
                                        <div>
                                            <strong>Notas de la Consulta</strong>
                                            <div>{{ consulta.notasConsulta }}
                                            </div>
                                        </div>
                                        <div>
                                            <v-btn size="small" width="auto" color="primary"
                                                @click="editarConsulta(consulta.id)">
                                                Editar Consulta
                                            </v-btn>
                                        </div>
                                    </template>
                                </v-timeline-item>
                            </v-timeline>
                        </v-expansion-panel-content>
                    </v-expansion-panel>

                </v-expansion-panels>
            </div>

            <div>

            </div>
        </template>

        <div class="text-center d-flex mt-3 pb-4">
            <v-btn class="ml-1" width="auto" color="secondary" @click="goToList">
                Volver al Listado
            </v-btn>
        </div>


    </div>
</template>

<script>
import PacienteDataService from "../services/PacienteDataService";
import ConsultaDataService from "../services/ConsultaDataService";


export default {
    name: "consulta",

    data() {
        return {
            color: {
                primary: "#14C6FF",
                secondary: "#424242",
                accent: "#82B1FF",
                error: "#FF5252",
                info: "#2196F3",
                success: "#4CAF50",
                warning: "#FFC107",
                lightblue: "#14c6FF",
                yellow: "#FFCF00",
                pink: "#FF1976",
                orange: "#FF8657",
                magenta: "#C33AFC",
                darkblue: "#1E2D56",
                gray: "#909090",
                neutralgray: "#9BA6C1",
                green: "#2ED47A",
                red: "#FF5c4E",
                darkblueshade: "#308DC2",
                lightgray: "#BDBDBD",
                lightpink: "#FFCFE3",
                white: "#FFFFFF"
            },
            dateStart: new Date().toISOString().substr(0, 10),
            dateEnd: new Date().toISOString().substr(0, 10),
            fechainicio_datePicker: false,
            modal: false,
            fechafinal_datePicker: false,
            multiLine: true,
            snackbar: false,
            panel: [],
            items: 3,
            alert: false,
            currentPaciente: {
                id: null,
                nombre: "",
                apellido: "",
                telefono: "",
                documento: ""
            },
            consultas: null
        };
    },

    methods: {

        async goToList() {
            this.$router.push("/pacientes");
        },
        getPaciente(id) {
            PacienteDataService.get(id)
                .then(response => {
                    this.currentPaciente = response.data;
                    console.log(response.data);
                })
                .catch(e => {
                    console.log(e);
                });
        },

        getAllConsultas(id) {
            ConsultaDataService.getAllConsultas(id)
                .then(response => {
                    this.consultas = response.data.consultas;
                    console.log(this.consultas);
                })
                .catch(e => {
                    console.log(e);
                });
        },

        editarConsulta(id) {
            this.$router.push({
                name: "consulta-edit",
                params: {
                    id: id
                }

            });
        },

    },
    mounted() {
        this.getPaciente(this.$route.params.id),
        this.getAllConsultas(this.$route.params.id)
    }
};
</script>

<style>
/* Hide up and down buttons on numeric type fields */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}
</style>
